.prime-footer {
    border-top: 1px solid #404c5c;
    &__logos {
        padding: 30px 0;
        @include media-breakpoint-down(sm){
            padding: 0;
            text-align: center;
        }
        &__wrapper {
            width: 100%;
                display: flex;
                justify-content: space-between;
                align-items: center;
            a {
                width: 25%;
            }
        }
        a {
            img {
                max-width: 100%;
                height: 90px;
                display: block;
                margin: 0 auto;
                @include media-breakpoint-down(md){
                    height: auto;
                    width: 80%;

                }
            }
        }
    }
    .container {
        padding: 0 8px;
    }
    &__color {
        height: 85px;
        background-color: #404c5c;
        display: flex;
        align-items: center;
        @include media-breakpoint-down(md){
            height: 40px;
        }
        .menu-footer-menu-container{
            width: 100%;
        }
        ul {
            margin: 0;
            padding: 0;
            list-style-type: none;
            display: flex;
            width: 100%;
            justify-content: flex-end;
            @include media-breakpoint-down(sm){
                justify-content: center;
            }
            li {
                a {
                    padding: 0 0 0 20px;
                    color: #fff;
                    transition: 250ms;
                    &:hover {
                        text-decoration: none;
                        opacity: .5;
                        transition: 250ms;
                    }
                }
            }
        }
    }
}