.header {
    position: relative;
    @include media-breakpoint-down(md){
        text-align: center;
    }
    &__top {
        background-color: #bdbec0;
        width: 100%;
        height: 50px;
        display: flex;
        align-items: center;
        ul {
            list-style-type: none;
            padding: 0;
            margin: 0;
            display: flex;
            li {
                a {
                    color: black;
                    font-size: 14px;
                    transition: 250ms;
                    &:hover {
                        transition: 250ms;
                    }
                }
                border-right: 1px solid black;
                padding: 0 30px;
                &:first-child {
                    padding: 0 30px 0 0;
                }
                &:last-child {
                    border-right: 0;
                }
                @include media-breakpoint-down(md){
                    padding: 0;
                    border: 0;
                    &:first-child {
                        padding: 0;
                    }
                    a {
                        color: white;
                        background-color: #a5ce41;
                        display: block;
                        width: 100%;
                        margin: 10px 0;
                        padding: 10px 0;
                        text-transform: uppercase;
                        border-radius: 180px;
                        &:hover {
                            text-decoration: none;
                        }
                    }
                }
            }
            @include media-breakpoint-down(md){
                width: 100%;
                display: block;
                li {
                    width: 100%;
                }
            }
        }
        @include media-breakpoint-down(md){
            height: 100%;
            background-color: #404c5c;
        }
    }
    img {
        padding: 20px 0 0 0;
    }
    &__button {
        display: flex;
        height: 100%;
        align-items: center;
        a {
            text-transform: uppercase;
            color: #fff;
            background-color: #404c5c;
            border-radius: 180px;
            height: 40px;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 14px;
            border: 1px solid #404c5c;
            transition: 250ms;
            &:hover {
                text-decoration: none;
                background-color: #fff;
                color: #404c5c;
                transition: 250ms;
            }
        }
        @include media-breakpoint-down(md){
            padding: 16px 0;
        }
    }
    &__connect {
        position: absolute;
        z-index: 99;
        height: 80px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        width: 100%;
        right: 8px;
        @include media-breakpoint-down(md){
            justify-content: center;
            right: 0;
            width: 100%;
            height: 60px;
        }
        span {
            color: #fff;
            font-weight: 600;
            letter-spacing: 1.1px;
            text-transform: uppercase;
            @include media-breakpoint-down(md){
                display: none;
            }
        }
        &__icons {
            padding: 0 0 0 50px;
            display: flex;
            @include media-breakpoint-down(md){
                padding: 0;
                width: 60%;
                justify-content: space-evenly;
            }
            a {
                margin: 0 10px;
                display: inline-block;
                color: #fff;
                transition: 250ms;
                &:hover {
                    opacity: .5;
                    transition: 250ms;
                }
                i {
                    font-size: 20px;
                    @include media-breakpoint-down(md){
                        font-size: 30px;
                    }
                }
            }
        }
    }
    &__shapeswrapper {
        position: absolute;
        width: 100%;
        display: contents;
    }
    &__shapepadding {
        padding: 0;
    }
    &__shape {
        background-color: #404c5c;
        height: 40px;
        @include media-breakpoint-down(md){
            display: none;
        }
        &--bottom {
            &::before {
                display: none;
            }
            @include media-breakpoint-down(md){
                display: block !important;
                height: 60px;
            }
        }
        &::before {
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 0 0 40px 45px;
            border-color: transparent transparent #404b5c transparent;
            display: block;
            position: absolute;
            top: 0;
            right: 100%;
            content: '';
        }
        @include media-breakpoint-down(md){
            display: none;
        }
    }
    &__logo {
        @include media-breakpoint-down(xl) {
            padding-bottom: 20px;
        }
    }
    &__info {
        @include media-breakpoint-down(xl) {
            border: none;
        }
        &__item {
            min-height: 60px;
            border-right: 1px solid #d7d8db;
            position: relative;
           &--first {
                border-left: 1px solid #d7d8db;
           }
            @include media-breakpoint-down(xl) {
                border: none;
            }
            &__content {
               text-align: left;
               position: absolute;
               left: 50%;
               top: 50%;
               transform: translate(-50%, -50%);
               width: 100%;
               padding-left: 25px;
               display: block;
               font-size: 14px;
               font-weight: bold;
               color: $blue;
               font-family: 'Muli';
               font-size: 12px;
               @include media-breakpoint-down(xl) {
                   padding-left: 0;
               }
               span {
                   display: block;
                   font-weight: 300;
                   font-family: 'Muli';
                   font-size: 14px;
                   padding: 0 0 0 14px;
                   margin: 0 0 7px 0;
                   font-weight: normal;
                   color: #939598;
                   span {
                       background: none;
                       padding: 0;
                       display: inline;
                   }
                   @include media-breakpoint-down(xl) {
                       font-size: 12px;
                       span {
                           display: none;
                       }
                   }
               }
            }
            &--last {
                text-align: left;
            }
        }
    }
    &__telephone {
        a {
            // color: $river_bed;
        }
        span {
            background-image: url('../images/icons/header-telephone-icon.png');
           background-position: left 4px center;
           background-repeat: no-repeat;
           background-size: 14px;
           padding: 0 0 0 22px;
           @media(max-width: 1784px) {
               background-image: none;
           }
           @include media-breakpoint-down(xl) {
                background-image: url('../images/icons/header-telephone-icon.png');
           }
       }
    }
    &__home__link {
       display: none !important;
        a {
            background-image: url('../images/icons/home-icon.png');
            font-size: 14px;
            padding-left: 20px;
            font-weight: 300;
            color: #939598;
        }
    }
    &__email {
        a {
            // color: $river-bed;
        }
        .header__info__item__content {
            font-size: 12px;
        }
        span {
            background-image: url('../images/icons/header-mail-icon.png');
            background-position: left center;
            background-repeat: no-repeat;
            background-size: 14px;
            padding: 0 0 0 20px;
        }
    }
    &__action {
        &__item {
           margin: 12px 0 0 0;
            a {
                padding: 5px;
                border-radius: 20px;
                font-size: 14px;
                font-weight: 500;
                text-transform: uppercase;
                color: $white;
            }
        }
    }
    &__basket {
        a {
            background-color: $green;
            max-width: 100px;
            margin: 0 auto;
            display: block;
            border-radius: 30px;
            text-align: center;
            width: 100%;
            background-image: url('../images/icons/basket-icon.png');
            background-repeat: no-repeat;
            background-position: right 18pX center;
        }
    }
    &__login {
        a {
            text-transform: uppercase;
            background-color: #216599;
            max-width: 100%;
            margin: 0 auto;
            display: block;
            border-radius: 30px;
            text-align: center;
            width: 100%;
            color: $white;
            &:hover {
                color: $white;
            }
        }
    }
    &__contractor {
        a {
            background: #404c5c;
            width: 100%;
            &:hover {
                color: $white;
            }
        }
    }
    &__navigation {
        background-color: #404c5c;
        position: relative;
        @include media-breakpoint-down(xl) {
            text-align: center;
        }
       &:after {
           position: absolute;
           width: 25%;
           left: -1px;
           content: '';
           height: calc(100% - 26px);
           top: 0px;
           background-color: #f4f6f7;
           background-image: url(../images/layout/header-shape.png);
           background-repeat: no-repeat;
           background-position: right 0px top;
            @include media-breakpoint-down(xl) {
               display: none;
           }
       }
        ul {
            list-style-type: none;
            margin: 0;
            padding: 0;
            font-size: 0;
            li {
                display: inline-block;
               padding: 30px 7px;
               position: relative;
               font-size: 13px;
               line-height: 1;
               &:hover {
                   > ul {
                       display: block;
                   }
               }
                 &.current-menu-item:after {
                   content: '';
                   height: 6px;
                   width: 100%;
                   background-color: $green;
                   position: absolute;
                   bottom: 0;
                   left: 0;
                }
                ul {
                    li {
                        &.current-menu-item:after {
                           display: none !important;
                        }
                    }
                }
                a {
                    text-decoration: none;
                    &:hover {
                        color: $green;
                    }
                }
                ul {
                    display: none;
                    position: absolute;
                    left: -30px;
                    top: 100%;
                    z-index: 10;
                    background-color: #414d5c;
                    width: 290px;
                    padding: 25px 20px 20px 20px;
                    z-index: 20;
                    &.sub-menu {
                        li {
                           &:hover {
                               ul {
                                   display: block;
                               }
                           }
                        }
                    }
                    li {
                        padding: 0 0 10px 0;
                        margin: 0 0 10px 0;
                        border-bottom: 1px solid $white;
                       display: block;
                       ul {
                           right: -310px;
                           left: auto;
                           top: -25px;
                       }
                        a {
                            text-transform: none;
                            font-size: 13px;
                            font-family: 'Muli';
                            text-decoration: none;
                            &:hover {
                                color: $green;
                            }
                        }
                       &:hover:after {
                           display: none;
                       }
                    }
                }
                a {
                    color: #fff;
                    text-transform: uppercase;
                }
            }
        }
        &--additional {
            background-color: transparent;
            position: absolute;
            right: 0;
            width: 100%;
            top: 100%;
            z-index: 10;
            @include media-breakpoint-down(xl) {
                text-align: center;
            }
            .background {
                background: #206497;
            }
            ul {
               list-style-type: none;
               li {
                   a {
                       color: #fff;
                   }
                   ul {
                       li.current-menu-item:after {
                           display: none !important;
                       }
                   }
               }
            }
            ul {
            list-style-type: none;
            margin: 0;
            padding: 0;
            font-size: 0;
            li {
                display: inline-block;
               padding: 30px 10px;
               position: relative;
               font-size: 12px;
               line-height: 1;
               @include media-breakpoint-down(xl) {
                   font-size: 11px;
               }
                 &.current-menu-item:after {
                   content: '';
                   height: 6px;
                   width: 100%;
                   background-color: $green;
                   position: absolute;
                   bottom: 0;
                   left: 0;
               }
               li.current-menu-item:after {
                   display: none !important;
               }
                a {
                    text-decoration: none;
                    &:hover {
                        color: $green;
                    }
                }
                ul {
                    display: none;
                    position: absolute;
                    left: 0;
                    top: 100%;
                    z-index: 10;
                    background-color: #236191;
                    width: 290px;
                    padding: 25px 20px 20px 20px;
                    li {
                        padding: 0 0 10px 0;
                        margin: 0 0 10px 0;
                        border-bottom: 1px solid $white;
                        display: block;
                        a {
                            text-transform: none;
                            font-size: 13px;
                            font-family: 'Muli';
                            text-decoration: none;
                            &:hover {
                                color: $green;
                            }
                        }
                       &:hover:after {
                           display: none;
                       }
                    }
                }
                &:hover {
                    ul {
                        display: block;
                    }
                }
                a {
                    color: #fff;
                    text-transform: uppercase;
                }
            }
        }
        &:after {
           position: absolute;
           width: 25%;
           left: 0;
           content: '';
           top: 0px;
           background-color: transparent;
           background-image: url(../images/layout/header-shape-blue.png);
           background-repeat: no-repeat;
           background-position: right -33px top 0px;
           height: 100%;
           @include media-breakpoint-down(xl) {
               display: none;
           }
           }
        }
        /* TECHNICAL RECRUITMENT MENU */
        &--technical_recruitment {
            .background {
                background: #a5ce41;
            }
            ul {
                li {
                    a:hover {
                        color: #404c5c;
                    }
                    ul {
                         display: none;
                        position: absolute;
                        left: -30px;
                        top: 100%;
                        z-index: 10;
                        background-color: #9dc345;
                        padding: 25px 20px 20px 20px;
                        li {
                            a:hover {
                               color: #404c5c;
                            }
                        }
                    }
                }
            }
            &:after {
               position: absolute;
               width: 25%;
               left: 0;
               content: '';
               top: 0px;
               background-color: transparent;
               background-image: url(../images/layout/header-shape-green.png);
               background-repeat: no-repeat;
               background-position: right -33px top 0px;
               height: 100%;
               @include media-breakpoint-down(xl) {
                   display: none;
               }
           }
        }
         &--flight_crew_recruitment {
            .background {
                background: #55bbea;
            }
            ul {
                li {
                    a:hover {
                        color: #404c5c;
                    }
                    ul {
                         display: none;
                        position: absolute;
                        left: -30px;
                        top: 100%;
                        z-index: 10;
                        background-color: #54b1de;
                        padding: 25px 20px 20px 20px;
                        li {
                            a:hover {
                               color: #404c5c;
                            }
                        }
                    }
                }
            }
            &:after {
               position: absolute;
               width: 25%;
               left: 0;
               content: '';
               top: 0px;
               background-color: transparent;
               background-image: url(../images/layout/header-shape-light-blue.png);
               background-repeat: no-repeat;
               background-position: right -33px top 0px;
               height: 100%;
               @include media-breakpoint-down(xl) {
                   display: none;
               }
           }
        }
   }
}
