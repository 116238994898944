.banner-span {
    background-color: #f5f6f8;
    &__heading {
        height: 100px;
        display: flex;
        align-items: center;
        width: 100%;
        justify-content: center;
        h1 {
            font-size: 36px;
            text-transform: uppercase;
            line-height: 1;
            margin: 0;
            color: #404c5c;
            font-weight: 600;
            letter-spacing: 1.1px;
            @include media-breakpoint-down(md){
                font-size: 30px;
            }
        }
    }
}

.quicklinks {
    &__wrapper {
        &__image {
            width: 100%;
            height: 500px;
            position: relative;
            background-size: cover;
            background-position: center;
            &::after {
                content: "";
                background-color: var(--backgroundoverlay);
                position: absolute;
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
                opacity: .8;
            }
            @include media-breakpoint-down(md){
                height: 300px;
            }
            @include media-breakpoint-down(sm){
                height: 600px;
            }
        }
        &__content {
            padding: 50px 30px;
            text-align: center;
            &__wrapper {
                margin: 0 0 30px 0;
            }
            h2 {
                font-size: 28px;
                color: #404c5c;
                line-height: 1;
                margin: 0;
                text-transform: uppercase;
                font-weight: 700;
                min-height: 75px;
                display: flex;
                // align-items: center;
                justify-content: center;
                @include media-breakpoint-down(md){
                    color: #fff;
                }
            }
            p {
                font-size: 14px;
                // margin: 0 0 30px 0;
                @include media-breakpoint-down(md){
                    color: #fff;
                }
            }
            a {
                text-transform: uppercase;
                color: #fff;
                background-color: #00659d;
                border-radius: 180px;
                padding: 10px 90px;
                border: 1px solid #00659d;
                transition: 250ms;
                display: inline-flex;
                &:hover {
                    text-decoration: none;
                    background-color: #fff;
                    color: #00659d;
                    transition: 250ms;
                }
                @include media-breakpoint-down(md){
                    display: block;
                    width: 100%;
                }
            }
            @include media-breakpoint-down(md){
                position: absolute;
                top: 0;
                padding: 0 30px;
                height: 100%;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
            }
        }
    }
}