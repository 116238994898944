.pagebanner {
    min-height: 500px;
    display: flex;
    align-items: center;
    position: relative;
    background-size: cover;
    @include media-breakpoint-down(md){
        min-height: 400px;
    }
    @include media-breakpoint-down(sm){
        min-height: 100%;
        padding: 20px 0;
    }
    &::after {
        background-color: #404c5c;
        opacity: .8;
        content: "";
        height: 100%;
        width: 100%;
        position: absolute;
        z-index: 1;
    }
    &__content {
        position: relative;
        z-index: 2;
        @media (max-width: 991px){
            padding: 0 20px;
        }
        @include media-breakpoint-down(md){
            text-align: center;
        }
        h3 {
            font-size: 18px;
            font-weight: 300;
            color: #a5ce41;
            line-height: 1;
            padding: 0 0 10px 0;
            text-transform: uppercase;
        }
        h2 {
            font-size: 42px;
            font-weight: 700;
            color: #fff;
            text-transform: uppercase;
            line-height: 1;
            margin: 0;
            padding: 0 0 15px 0;
        }
        p {
            color: #fff;
            font-weight: 300;
            padding: 0 0 25px 0;
        }
        a {
            color: #fff;
            padding: 10px 85px;
            background-color: #a5ce41;
            font-weight: 300;
            font-size: 20px;
            border-radius: 180px;
            transition: 500ms;
            text-transform: uppercase;
            display: inline-flex;
            &:hover {
                color: #a5ce41 !important;
                background-color: #fff;
                transition: 500ms;
                text-decoration: none;
            }
        }
    }
}

.features
{
    &__header
    {
        text-align: center;
        max-width: 930px;
        display: block;
        margin: 60px auto 50px auto;

        h1, h2, h3, h4, h5
        {
            font-weight: bold;
        }
    }

    &__block
    {
        background-color: #454C5D;
        min-height:360px;
        margin:0 0 30px 0;
        text-align: center;
        position: relative;
        padding: 0 20px;

        &:hover {
            .features__block__icon {
                opacity:0;
            }

            .features__block__text {
                opacity:1;
                display: block;
                max-height:2000px;
                overflow: visible;

            }
        }


        // Content
        &__title 
        {
            color:#fff;
            font-size: 20px;
            text-transform: uppercase;
            padding:65px 0 45px 0;

            max-width: 250px;
            display: block;
            margin:0 auto;
        }

        &__icon
        {
            color:#fff;
            font-size:160px;
            position: absolute;
            opacity: 1;
            top: 68%;
            transform: translate(-50% , -50%);
            transition: 250ms;
            pointer-events: none;
        }

        &__text
        {
            max-height: 0;
            overflow: hidden;
            color:#fff;
            transition: 250ms;
        }

        &__link {
            font-size: 16px;
            color: white;
            text-decoration: none;
            text-transform: uppercase;
            margin: 20px 0 0 0;
            &:hover {
                color:#fff;
                font-weight: bold;
            }
        }
    }
}

.faq-feature-heading {
    font-size: 30px !important;
} 

.your-lms {
    .apprenticeship-testimonial-block__video {
        margin: 0;
    }

    .apprenticeship-testimonial-block__image {
        display: flex;
        align-items: center;
    }
    .contact-details {
        padding: 50px 0;
    }
    .standard-content {
        background-color: white;
        h3 {
            font-size: 30px;
        }
        a {
            color: white;
        }
    }

    .website-popup {
        position: fixed;
        width: 100vw;
        height: 100vh;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 99999;
    }
    
    .website-popup--inactive {
        display: none;
    }
    
    .website-popup__close {
        height: 53px;
        cursor: pointer;
        width: 53px;
        background-color: #404c5c;
        position: absolute;
        top: 0;
        right: 0;
        color: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 26px;
        line-height: 1;
    }
    
    
    .website-popup__overlay {
        position: absolute;
        height: 100%;
        width: 100%;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background: #000;
        opacity: 0.3;
        z-index: 40;
    }
    
    .website-popup__inner {
        position: absolute;
        height: auto;
        width: 100%;
        max-width: 60%;
        background-color: #fff;
        z-index: 50;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        padding: 60px;
        text-align: center;
    }
    
    @media(max-width: 991px) {
        .website-popup__inner {
            padding: 20px;
            max-width: 95%;
            overflow-y: scroll;
            max-height: 90vh;
        }
    }
    
    .website-popup__title {
        font-size: 30px;
        margin: 0 0 35px 0;
        line-height: 1;
        font-weight: 700;
    }
    
    @media(max-width: 991px) {
        .website-popup__title {
            font-size: 28px;
            margin: 0 0 20px 0;
        }
    }
    
    .website-popup__title--sale {
        display: flex;
        align-items: center;
        justify-content: center;
    }
    
    .website-popup__title__icon {
        margin: -20px 0 0 12px;
    
    }
    
    @media(max-width: 991px) {
        .website-popup__title--sale {
            display: block;
        }
    
        .website-popup__title__icon {
            display: block;
            margin: 10px auto;
        }
    
    }
    
    
    
    .website-popup__text {
        font-size: 22px;
        font-weight: 400;
        margin: 0 0 22px 0;
        font-family: 'Raleway';
    }
    
    @media(max-width: 991px) {
        .website-popup__text {
            font-size: 18px;
        }
    
    }
    
    .website-popup__text span {
        font-weight: 600;
        color: #a5ce41;
    }
    
    .website-popup__fine { 
        font-size: 14px;
        font-weight: 700;
        color: #404c5c;
        margin: 0 0 35px 0;
    }
    
    @media(max-width: 991px) {
        .website-popup__fine {
            margin: 0 0 20px 0;
        }
        .website-popup__fine br {
            display: none;
        }
    }
    
    .website-popup__buttons {
        font-size: 0;
    }
    
    .website-popup__buttons a {
        max-width: 100%;
        display: inline-block;
        width: calc(50% - 12px);
        font-size: 400;
        text-decoration: none;
        font-size: 14px;
        color: #fff;
        background-color: #a5ce41;
        border-radius: 25px;
        line-height: 1;
        padding: 10px 10px;
        margin-right: 24px;
        text-transform: uppercase;
    }
    
    @media(max-width: 991px) {
        .website-popup__buttons a {
            display: block;
            width: 100%;
            max-width: 100%;
            margin: 10px 0;
        }
    }
    
    .website-popup__buttons a:last-child {
        margin-right: 0;
    }
    .standard-hero {
        @media(max-width: 768px) {
            height:500px;
            min-height: 500px;
        }
        h1 {
            padding-left: 0 !important;
        }
        &__new-text {
            margin: 0 0 40px 0;
            color: white;
        }
        p {
            .green {
                color: #a5ce41;
            }
        }
    }

}

body {
    &.popup-active {
        margin: 0;
        height: 100%;
        overflow: hidden;
    }
}

.main-content__popup-link {
    background-color: #206497;
    border-radius: 25px;
    font-size: 14px;
    padding: 5px 15px;
    margin: 25px 0 0 0;
    text-align: center;
    color: white !important;
    text-transform: uppercase;
    display: inline-block;
    margin: 0 0 10px 0;
    cursor: pointer;
}

.standard-content {
    background-color: white;
    h3 {
        font-size: 30px;
    }
    // a {
    //     color: white;
    // }
    &__inner {
        &--hr {
            padding: 20px 30px !important;
        }
    }
}

.website-popup__inner {
    padding: 0 !important;
}

.contact-details {
    padding: 40px !important;
    h1 {
        margin: 0 0 16px 0;
        font-size: 28px;
    }
	.contact-form {
		&.flight_crew_recruitment {
			input[type='submit'] {
				max-width: 130px;
				display: inline-block;
				@include media-breakpoint-down(md) {
					max-width: 100%;
				}
			}
		}
        label {
            display: block;
            text-align: left;
            text-transform: uppercase;
            color: #414c5d;
            font-weight: 600;
            margin: 0 0 5px 0;
        }
        input {
            display: block;
            width: 100%;
            margin: 0 0 8px 0;
            padding: 10px;
            border-radius: 180px;
            border: 1px solid grey;
            background: none;
            outline: none;
            margin: 0 0 16px 0;
        }
        textarea {
            width: 100%;
            border-radius: 10px;
            background-color: none;
            outline: none;
            padding: 10px;
        }
        p {
            margin: 0 !important;
        }
        input[type='submit']{
            width: 25%;
            margin: auto;
            border-radius: 10px;
            background-color: #a5ce41;
            color: #fff;

        }
	}
    .ajax-loader {
        display: none !important;
    }
}