$brand-primary: #1e2957;
$brand-secondary: #fff;
$brand-third: #e0393d;
$body-colour: #f4f6f7;
$green: #a5ce41;
$white: #fff;
$blue: #404c5c;
$iron: #E6e7e8;
$piston-blue: #56BAEA;

$oslo_gray: #939598;
$yellow_green: #C0DD7A;
$gay_chateau: #9FA5AE;
$river_bed: #414c5d;
$transparent: transparent;
