.contact-banner {
    background-color: #fafafa;
    padding: 50px 0;
    @include media-breakpoint-down(sm){
        padding: 25px 8px;
    }
    &__header {
        h1 {
            text-transform: uppercase;
            margin: 0;
            line-height: 1;
            font-size: 30px;
            margin: 0 0 30px 0;
            color: #404c5c;
        }
        p {
            font-size: 14px;
            margin: 0 0 30px 0;
        }
        @include media-breakpoint-down(sm){
            text-align: center;
        }
    }
    &__selectheading {
        color: #404c5c;
        text-transform: uppercase;
        font-weight: 500;
        font-size: 14px;
        display: block;
        margin: 0 0 10px 0;
        @include media-breakpoint-down(sm){
            text-align: center;
        }
    }
    &__select {
        display: block;
        width: 100%;
        border-radius: 180px;
        height: 50px;
        padding: 0 30px;
        border: 1px solid #e5e6e7;
        -webkit-appearance: none;
        outline: none;
        option {
            font-size: 14px;
            color: #404c5c;
            font-weight: 300;
            @include media-breakpoint-down(sm){
                text-align: center;
            }
        }
    }
    &__details {
        display: flex;
        justify-content: space-evenly;
        @include media-breakpoint-down(md){
            justify-content: space-between;
            padding: 30px 0 0 0;
        }
        @include media-breakpoint-down(sm){
            display: block;
            padding: 0;
        }
    }
    &__general {
        display: block;
        span {
            color: #a5ce40;
            text-transform: uppercase;
        }
        a {
            display: block;
        }
        &__phone {
            color: #404c5c;
            font-weight: 500;
            transition: 250ms;
            &::before {
                content: "\f879";
                font-family: "Font Awesome 5 Pro";
                color: #a5ce40;
                font-weight: 100;
                padding: 0 5px 0 0;
            }
            &:hover {
                text-decoration: none;
                opacity: .6;
                transition: 250ms;
                color: #404c5c;
            }
        }
        &__email {
            color: #404c5c;
            font-weight: 500;
            transition: 250ms;
            &::before {
                content: "\f0e0";
                font-family: "Font Awesome 5 Pro";
                color: #a5ce40;
                font-weight: 100;
                padding: 0 5px 0 0;
            }
            &:hover {
                text-decoration: none;
                opacity: .6;
                transition: 250ms;
                color: #404c5c;
            }
        }
        @include media-breakpoint-down(sm){
            display: block;
            width: 100%;
            padding: 16px 0;
            text-align: center;
        }
    }
    &__contact {
        color: #a5ce40;
        display: block;
        span {
            color: #a5ce40;
            text-transform: uppercase;
        }
        p {
            display: block;
            color: #404c5c;
        }
        @include media-breakpoint-down(sm){
            display: block;
            width: 100%;
            text-align: center;
        }
    }
}