.overlapping-block {
    // height: 800px;
    // display: flex;
    // align-items: center;
    background-color: #fafafa;
    padding: 75px 0 0 0;
    @media (max-width: 1199px){
        padding: 75px 0;
    }
    @media (max-width: 991px){
        padding: 30px 16px;
    }
    &__text {
        width: 600px;
        @media (max-width: 1559px){
            width: 100%;
            padding: 0 0 10px 0;
            margin: 0;
        }
        h3 {
            color: #a5ce41;
            font-size: 18px;
            font-weight: 300;
            line-height: 1;
            padding: 0 0 10px 0;
        }
        h2 {
            color: #404c5c;
            font-size: 42px;
            font-weight: 700;
            text-transform: uppercase;
            line-height: 1;
            margin: 0;
            padding: 0 0 15px 0;
            @include media-breakpoint-down(md){
                font-size: 30px;
            }
        }
        p {
            color: #404c5c;
            font-weight: 300;
        }
    }
    &__imageone {
        z-index: 2;
        position: relative;
        background-color: #d1d3d4;
        height: 300px;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        @media (max-width: 991px){
            display: none;
        }
    }
    &__imagetwo {
        position: relative;
        z-index: 1;
        transform: translate(20%, -50%);
        background-color: #a7a9ac;
        height: 300px;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        @media (max-width: 1199px){
            transform: translate(0);
            padding: 0 16px;
        }
        @media (max-width: 991px){
            display: none;
        }
        &__cutout {
            position: absolute;
            bottom: 0;
            left: -1px;
            background-color: #fafafa;
            width: 60px;
            height: 45px;
            &::after {
                width: 0;
                height: 0;
                border-style: solid;
                border-width: 46px 0 0 45px;
                border-color: transparent transparent transparent #fafafa;
                content: '';
                position: absolute;
                left: 59px;
                top: -1px;
            }
        }
    }
}

.logodivider {
    padding: 50px 0;
    background-color: #fff;
    hr {
        border: none;
        background-color: #e8e8e8;
        height: 1px;
    }
    &__image {
        position: absolute;
        right: 50%;
        top: 50%;
        transform: translate(50%, -50%);
        background-color: #fff;
        padding: 0 100px;
        @media (max-width: 991px){
            padding: 0 50px;
        }
    }
}

.core-values {
    background-color: #fff;
    &__heading {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        padding-top: 50px;
        @media (max-width: 991px){
            padding: 0 16px;
        }
        
        h2 {
            color: #404c5c;
            font-size: 42px;
            font-weight: 700;
            text-transform: uppercase;
            line-height: 1;
            margin: 0;
            padding: 0 0 15px 0;
            @include media-breakpoint-down(md){
                text-align: center;
                font-size: 30px;
            }
        }
        h3 {
            color: #a5ce41;
            font-size: 18px;
            font-weight: 300;
            line-height: 1;
            padding: 0 0 10px 0;
            margin: 0;
        }
        p {
            color: #404c5c;
            font-weight: 300;
            text-align: center;
            width: 930px;
            @media (max-width: 991px){
                width: 100%;
            }
        }
    }
    &__cards {
        padding: 80px 0;
        display: flex;
        width: 100%;
        justify-content: space-between;
        @media (max-width: 1199px){
            display: block;
        }
        @media (max-width: 991px){
            padding: 20px 16px;
        }
        &__single {
            width: 280px;
            height: 290px;
            background-color: #404c5c;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            position: relative;
            @media (max-width: 1643px){
                margin: 0 8px;
            }
            @media (max-width: 1199px){
                width: 100%;
                margin: 16px 0;
            }
            @include media-breakpoint-down(md){
                height: auto;
                padding: 30px 0;
            }
            &__icon {
                max-height: 2000px;
                font-size: 70px;
                font-weight: 300;
                color: #b2b4ba;
                transition: 750ms;
                @media (max-width: 1199px){
                    padding: 0 0 15px 0;
                }
                @include media-breakpoint-down(sm){
                    display: none;
                }
            }
            &__heading {
                color: #fff;
                font-weight: 700;
                font-size: 18px;
            }
            &__copy {
                color: #b2b4ba;
                font-weight: 300;
                text-align: center;
                padding: 0 10px;
                max-height: 0;
                transition: 500ms;
                overflow: hidden;
                @media (max-width: 1199px){
                    max-height: 2000px;
                    padding: 0 40px;
                }
            }
            &__hover {
                position: absolute;
                height: 5px;
                width: 100%;
                bottom: 0;
                position: absolute;
                background-color: #3dbdee;
                transition: 500ms;
            }
            &__hover-image {
                position: absolute;
                height: 100%;
                width: 100%;
                background-repeat: no-repeat;
                background-position: center;
                opacity: 0;
                overflow: hidden;
                transition: 500ms;
            }
            &:hover &{
                &__copy {
                    max-height: 2000px;
                    overflow: hidden;
                    transition: 500ms;
                    padding: 0 10px 0 10px;
                    @media (max-width: 1199px){
                        padding: 0 40px;
                    }
                }
                &__icon {
                    max-height: 0;
                    overflow: hidden;
                    padding: 0;
                    transition: 500ms;
                    @media (max-width: 1199px){
                        max-height: 2000px;
                        padding: 0 0 15px 0;
                    }
                }
                &__hover {
                    background-color: #97989c;
                    transition: 500ms;
                }
                &__hover-image {
                    opacity: 1;
                    transition: 500ms;
                }
            }
        }
    }
}

.mission-vision-statement {
    background-color: #fff;
    &__content {
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 50px 0;
        width: 100%;
        @media (max-width: 991px){
            padding: 10px 16px;
        }
        h2 {
            font-weight: 700;
            color: #404c5c;
            font-size: 42px;
            @include media-breakpoint-down(md){
                font-size: 30px;
            }
        }
        p {
            width: 950px;
            font-weight: 300;
            @media (max-width: 991px){
                width: 100%;
            }
        }
    }
}

.team-members-select {
    padding: 40px 0 75px 0;
    background-color: #fff;
    @media (max-width: 991px){
        padding: 20px 0;
    }
    &__heading {
        width: 100%;
        display: flex;
        justify-content: center;
        h2 {
            font-weight: 700;
            color: #404c5c;
            font-size: 42px;
            padding: 0 0 80px 0;
            @media (max-width: 991px){
                padding: 0 16px 20px 16px;
                text-align: center;
            }
            @include media-breakpoint-down(md){
                font-size: 30px;
            }
        }
    }
    &__wrapper {
        display: flex;
        justify-content: space-between;
        position: relative;
        width: 100%;
        @media (max-width: 1199px){
            display: block;
            right: -75%;
            transform: translateX(-50%);
        }
        @media (max-width: 991px){
            right: 0;
            transform: translateX(0);
            padding: 0 16px;
        }
        &__content {
            width: 290px;
            height: 250px;
            background-color: #97989c;
            background-size: cover;
            background-repeat: no-repeat;
            @media (max-width: 1559px){
                width: 220px;
            }
            @media (max-width: 1199px){
                width: 50%;
            }
            @media (max-width: 991px){
                width: 100%;
                height: 350px;
            }
        }
        &__name {
            background-color: #454c5d;
            position: relative;
            @media (max-width: 1199px){
                width: 50%;
            }
            @media (max-width: 991px){
                width: 100%;
            }
            h3 {
                padding: 0 10px;
                margin: 0;
                height: 50px;
                width: 100%;
                display: flex;
                align-items: center;
                color: #fff;
                font-weight: 300;
                font-size: 18px;
                transition: 250ms;
                &::after {
                    content: "\f061";
                    font-family: "Font Awesome 5 Pro";
                    font-size: 14px;
                    height: 30px;
                    width: 30px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    position: absolute;
                    right: 10px;
                    border-radius: 360px;
                    border: 1px solid #9ca0a9;
                    color: #9ca0a9;
                    transition: 250ms;
                }
            }
        }
        .card_popup {
            &:hover {
                .team-members-select__wrapper__name{
                    h3{
                        color: #a5ce41;
                        transition: 250ms;
                        &::after {
                            color: #a5ce41;
                            border: 1px solid #a5ce41;
                            transition: 250ms;
                        }
                    }
                }
            }
        }
    }
}

.ourbrands {
    padding: 70px 0 100px 0;
    background-color: #fff;
    @media (max-width: 991px){
        padding: 30px 0;
    }
    &__contactairlogo {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 90px 0 0 0;
        transition: 250ms;
        // height: 250px;
        @media (max-width: 1199px){
            padding: 35px 0;
            height: auto;
        }
        img {
            padding: 0 0 30px 0;
            max-width: 100%;
            height: 110px;
        }
        &__content {
            display: flex;
            justify-content: center;
            max-height: 0;
            overflow: hidden;
            transition: 250ms;
            @media(max-width: 1199px){
                transition: none;
            }
            p {
                padding: 25px 0;
                @media (max-width: 991px){
                    width: 100%;
                    padding: 0 16px;
                }
            }
            a {
                color: #404c5c;
                font-weight: 700;
                transition: 250ms;
                &:after {
                    content: "\f054";
                    font-family: "Font Awesome 5 Pro";
                    font-size: 12px;
                    color: #404c5c;
                    padding: 0 0 0 10px;
                }
                &:hover {
                    text-decoration: none;
                    opacity: .8;
                    transition: 250ms;
                }
                @media (max-width: 991px){
                    padding: 0 16px;
                }
            }
        }
        &:hover {
            filter: grayscale(0);
            transition: 250ms;
        }
    }

    &__resourcedesignlogo {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 90px 0 0 0;
        // height: 250px;
        transition: 250ms;
        @media (max-width: 1199px){
            padding: 35px 0;
            height: auto;
        }
        img {
            padding: 0 0 30px 0;
            max-width: 100%;
            height: 110px;
        }
        &__content {
            display: flex;
            justify-content: center;
            max-height: 0;
            overflow: hidden;
            transition: 250ms;
            @media(max-width: 1199px){
                transition: none;
            }
            p {
                padding: 0 0 25px 0;
                @media (max-width: 991px){
                    width: 100%;
                }
            }
            a {
                color: #404c5c;
                font-weight: 700;
                transition: 250ms;
                &:after {
                    content: "\f054";
                    font-family: "Font Awesome 5 Pro";
                    font-size: 12px;
                    color: #404c5c;
                    padding: 0 0 0 10px;
                }
                &:hover {
                    text-decoration: none;
                    opacity: .8;
                    transition: 250ms;
                }
            }
        }
        &:hover {
            filter: grayscale(0);
            transition: 250ms;
        }
    }

    &__iacademylogo {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 90px 0 0 0;
        transition: 250ms;
        // height: 250px;
        @media (max-width: 1199px){
            padding: 35px 0;
            height: auto;
        }
        img {
            padding: 0 0 30px 0;
            max-width: 100%;
            height: 110px;
        }
        &__content {
            display: flex;
            justify-content: center;
            max-height: 0;
            overflow: hidden;
            transition: 250ms;
            @media(max-width: 1199px){
                transition: none;
            }
            p {
                padding: 0 0 25px 0;
                @media (max-width: 991px){
                    width: 100%;
                }
            }
            a {
                color: #404c5c;
                font-weight: 700;
                transition: 250ms;
                &:after {
                    content: "\f054";
                    font-family: "Font Awesome 5 Pro";
                    font-size: 12px;
                    color: #404c5c;
                    padding: 0 0 0 10px;
                }
                &:hover {
                    text-decoration: none;
                    opacity: .8;
                    transition: 250ms;
                }
            }
        }
        &:hover {
            filter: grayscale(0);
            transition: 250ms;
        }
    }

    &__onecalllogo {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 90px 0 0 0;
        transition: 250ms;
        // height: 250px;
        @media (max-width: 1199px){
            padding: 35px 0;
            height: auto;
        }
        img {
            padding: 0 0 30px 0;
            max-width: 100%;
            height: 110px;
        }
        &__content {
            display: flex;
            justify-content: center;
            max-height: 0;
            overflow: hidden;
            transition: 250ms;
            @media(max-width: 1199px){
                transition: none;
            }
            p {
                padding: 0 0 25px 0;
                @media (max-width: 991px){
                    width: 100%;
                }
            }
            a {
                color: #404c5c;
                font-weight: 700;
                transition: 250ms;
                &:after {
                    content: "\f054";
                    font-family: "Font Awesome 5 Pro";
                    font-size: 12px;
                    color: #404c5c;
                    padding: 0 0 0 10px;
                }
                &:hover {
                    text-decoration: none;
                    opacity: .8;
                    transition: 250ms;
                }
            }
        }
        &:hover {
            filter: grayscale(0);
            transition: 250ms;
        }
    }
}

.page-accreditations {
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    padding: 50px 0;
    &::after {
        content: "";
        position: absolute;
        height: 100%;
        width: 100%;
        background-color: #454c5d;
        opacity: .9;
        top: 0;
        left: 0;
        z-index: 1;
    }
    &__content {
        position: relative;
        z-index: 2;
        width: 100%;
        h2 {
            color: #fff;
            font-size: 42px;
            font-weight: 700;
            text-transform: uppercase;
            line-height: 1;
            margin: 0;
            padding: 0 0 15px 0;
            display: flex;
            align-items: center;
            flex-direction: column;
            @include media-breakpoint-down(md){
                font-size: 30px;
            }
        }
        h3 {
            color: #a5ce41;
            font-size: 18px;
            font-weight: 300;
            line-height: 1;
            padding: 0 0 10px 0;
            display: flex;
            align-items: center;
            flex-direction: column;
        }
        &__slider {
            padding: 20px 0 0 0;
            &__image {
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                height: 220px;
            }
            img {
                max-width: 100%;
                transition: 250ms;
            }
        }
    }
}

.brandimages {
    cursor: pointer;
    filter: grayscale(1);
    transition: 250ms;
}

.open {
    max-height: 2000px;
    overflow: hidden;
    transition: 500ms;
    @media(max-width: 1199px){
        transition: none;
    }
}

.grayscale {
    filter: grayscale(0);
    transition: 250ms;
}

.custom-modal {
	display: none;
	z-index: 999999999999;
	position: absolute;
	background-color: rgba(0,0,0,0.5);
	width: 100%;
	height: 100%;
	top: 0;
    left: 0;
	color: #768692;
    &__wrapper {

    	position: fixed;
    	padding: 50px 60px 60px 60px;
    	width: 75%;
    	background-color: white;
    	top: 50%;
    	left: 50%;
		transform: translate(-50%, -50%);
        
        img {
            display: inline-block;
            height: 303px;
			width: 309px;
        }

        &__popup {
            display: inline-block;
            position: absolute;
            top: 0;
            padding: 38px 90px 0 90px;
        }
    	
		.close {
			position: absolute;
			top: 10px;
			right: 10px;
			height: 35px;
			width: 35px;
            cursor: pointer;
            z-index: 9999;
			i {
				line-height: 35px;
				color: #a5ce41;
				text-align: center;
				width: 35px;
				font-size: 18px;
			}
		}
		h2 {
            font-weight: 900;
		}
		.date {
			display: block;
		}
	}
	&.modalopen {
		display: block;
        position: fixed;
	}
	
	&.science-modal {
		h2 {
			color: black;
			span {
				color: #F67B56;
			}
		}
	}

}

.image-modal {

		&__wrapper {
			overflow: hidden !important;
			height: auto !important;
			width: 65% !important;
	
			.close {
				top: 15px;
				right: 15px;
			}
			img {
				display: block !important;
				margin: 0 auto !important;
            	height: auto !important;
				width: 100% !important;
			}
		}
	}


.modeopen {
    display: block;
}

.popup__name {
    font-weight: 700;
    color: #a5ce41;
    font-size: 28px;
    &::after {
        content: "";
        background-color: #a5ce41;
        height: 1px;
        width: 100%;
        display: block;
        margin: 20px 0;
    }
}

.card_popup {
    cursor: pointer;
    @include media-breakpoint-down(md){
        margin: 0 0 16px 0;
    }
}

.widthcontainer {
    @include media-breakpoint-down(md){
        text-align: center;
    }
}