body, html
{
    font-family: 'Mulish', sans-serif;
    font-size: 16px;
    color:"";
    position: relative;
}

body {
    overflow-x: hidden;
}

h1, h2, h3, h4, h5, a{
    font-family: 'Mulish', sans-serif;
}

.slick-initialized .slick-slide {outline: none;}