// Variables
$gold: #ab9651;
$grey:#3C4246;
$sand:#C6B492;
$header:rgba(#fff, 0.5);
$green:#7A987C;

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 1190px,
  xl: 1644px
);
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 1190px,
  xl: 1644px
);
$grid-gutter-width: 16px;

// Bootstrap
@import "../../node_modules/bootstrap/scss/functions";
@import "../../node_modules/bootstrap/scss/variables";
@import "../../node_modules/bootstrap/scss/mixins";
@import "../../node_modules/bootstrap/scss/reboot";
@import "../../node_modules/bootstrap/scss/grid";
@import "../../node_modules/bootstrap/scss/bootstrap-grid.scss";
@import "../../node_modules/bootstrap/scss/popover";
@import "../../node_modules/bootstrap/scss/utilities";
@import "../../node_modules/bootstrap/scss/tooltip";
@import "../../node_modules/slick-carousel/slick/slick.scss";


// // Import Bootstrap SASS
@import "../../layout/body";
@import "../scss/pages/about.scss";

@import "_header";
@import "_colours";
@import "_mobile-header";
@import "_footer";
@import "_front-page";
@import "_contact-span";
@import "_page-banner";