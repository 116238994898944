.mobile-header {
	padding: 30px 0 0 0;
	@include media-breakpoint-down(sm) {
		padding-top: 15px;
	}

	&__logo {
		img {
			display: block;
			margin: 0 auto;
			@include media-breakpoint-down(sm) {
				max-width: 150px;
				margin: 0 auto;
			}
		}
	}
	&__menu {
		border-bottom: 3px solid #fff;
    	&__item {
    		height: 100%;
    		padding: 15px 0 15px 0;
    		@include media-breakpoint-down(sm) {
    			padding: 10px 0 10px 0;
    		}
    		a {
    			color: $white;
    		}
    		color: #fff;
    		text-align: center;
    		text-transform: uppercase;
    		background-color: #404c5c;
    		&--basket {
    			background-color: #a5ce41;
    		}
    		&--login {
    			background-color: #216599;
    		}
    	}

			.row {
				margin:0px;
			}
	}
}

#slideout-menu {
	background: #404c5c;

	.website-menu-logo {
		background-color: $white;
		img {
			max-width: 100px;
			margin: 0 auto;
			display: block;
			padding: 15px 0 0 0;
		}
	}

	.slideout-menu__title {
		padding: 0 0 10px 30px;
		font-weight: bold;
		text-transform: uppercase;
		font-size: 12px;
	}

	.menu-main-menu-container, .menu-aviation-technical-recruitment-container, .menu-aviation-technical-training-container, .menu-flight-crew-recruitment-container  {
		margin-top: 20px;
	}

	 > ul {
		list-style-type: none;
		padding: 0;
		padding-top: 30px;
		> li {
			max-height: 30px;
			transition: 250ms max-height;
			overflow: scroll;
			color: $white;
			margin-bottom: 30px;
			background-image: url(../images/icons/accordion-arrow-inactive.png);
			background-position: right 15px top 4px;
			background-repeat: no-repeat;
			background-size: 6px auto;

			> ul {
				padding-left: 0;
				> li {
					padding-left: 15px;
					border-bottom: 1px solid $white;
				}
			}
			&.active {
				max-height: 10000px;
				
				background-image: url(../images/icons/accordion-arrow-active.png);
				background-size: 10px auto;
			}
			> a {
				color: $white;
			}
		}
	}
}
